import React from 'react';
import SEO from '../../components/seo';
import ArtsIntroduction from '../../components/desktop/ArtsIntroduction/ArtsIntroduction';

const Arts = () => {
  return (
    <>
      <SEO title={'Dzieła'} />
      <ArtsIntroduction />
    </>
  );
};

export default Arts;
