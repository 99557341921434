import {Link} from 'gatsby';
import styled, {keyframes, css} from 'styled-components';
import CustomLink from '../../../atoms/CustomLink';

export const PageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  background-color: #525252;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const start = keyframes`
  0% {
    transform: translateY(calc(-100vh + 102px));
  }
  100% {
    transform: translateY(0);
  }
`;

const opacityAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const animated = css`
  &:nth-child(1) {
    animation: ${start} 1s forwards;
  }
  &:nth-child(2) {
    animation: ${start} 1s forwards;
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation: ${start} 1s forwards;
    animation-delay: 1s;
  }
  &:nth-child(4) {
    animation: ${start} 1s forwards;
    animation-delay: 1.5s;
  }
`;

export const ColumnContainer = styled(CustomLink)`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: calc(100vh - 102px);
  transform: translateY(calc(-100vh - 102px));
  background-color: ${({backgroundColor}) => backgroundColor};
  ${({isAnimated}) => (isAnimated ? animated : '')};

  @media (max-width: 1500px) {
    height: calc(50vh - 51px);
  }

  @media (max-width: 1024px) {
    height: calc(50vh - 34px);
  }
`;

export const Title = styled.h3`
  font-family: 'LATOBLACK', sans-serif;
  font-size: 38px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 50px;
  letter-spacing: 2.25px;
  text-shadow: 0 0 15px rgba(8, 9, 8, 0.31);
  color: #ffffff;
  width: 100%;
  text-align: center;
  opacity: 0;
  animation: ${opacityAnim} 0.5s forwards;
  animation-delay: 2.8s;

  @media (max-width: 500px) {
    font-size: 28px;
  } ;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({src}) => src});
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`;
