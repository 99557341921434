import React from 'react';
import {ColumnContainer, Image, PageContainer, Title} from './styles';

import category1 from '../../../images/photos/category1.png';
import category2 from '../../../images/photos/category2.png';
import category3 from '../../../images/photos/category3.png';
import category4 from '../../../images/photos/category4.png';

const ArtsIntroduction = () => {
  return (
    <PageContainer>
      <ColumnContainer
        isAnimated={true}
        to={'/pl/category-1'}
        backgroundColor={'#a3c0c0'}>
        <Image src={category1}>
          <Title>
            Przedstawienie <br /> postaci
          </Title>
        </Image>
      </ColumnContainer>
      <ColumnContainer
        isAnimated={true}
        to={'/pl/category-2'}
        backgroundColor={'#e09087'}>
        <Image src={category2}>
          <Title>
            Marszałek <br /> Józef Piłsudski
          </Title>
        </Image>
      </ColumnContainer>
      <ColumnContainer
        isAnimated={true}
        to={'/pl/category-3'}
        backgroundColor={'#e0c887'}>
        <Image src={category3}>
          <Title>Płaskorzeźby</Title>
        </Image>
      </ColumnContainer>
      <ColumnContainer
        isAnimated={true}
        to={'/pl/category-4'}
        backgroundColor={'#afa3c0'}>
        <Image src={category4}>
          <Title>
            Pozostałe <br /> dzieła
          </Title>
        </Image>
      </ColumnContainer>
    </PageContainer>
  );
};

export default ArtsIntroduction;
